import axios from "axios";
import CryptoJS, {AES, enc} from "crypto-js";
import {atom} from "recoil";

const secret = "!#@$#";

//const API_URL = "http://localhost/backn/api";
// const API_URL = "https://tcntccu.csinformationsys.com/bk";
const API_URL = "https://cageccu.csinformationsys.com/bk";

export function moneyFormat(val) {
    return Number(val).toFixed(2).replace(/\d(?=(\d{3})+\.)/, '$&,');
    //return new Intl.NumberFormat('en-US', { style: 'currency'}).format(val)
}

export function GetUsername() {
    const user = localStorage.getItem("user");
    if (user !== null) {
        return user
    }
    window.location.replace("/");
}

export function HashPassword(str) {
    let hash = CryptoJS.SHA512(str);
    return hash.toString().toUpperCase();
}

export async function axiosGet(url) {
    let config = {
        method: "GET",
        url: `${API_URL}/${url}`
    }
    let {data} = await axios(config);
    return data;
}

export async function axiosPost(url, payload) {
    let config = {
        method: "POST",
        url: `${API_URL}/${url}`,
        data: payload
    }
    let {data} = await axios(config);
    return data;
}

export function getUsername() {
    return localStorage.getItem("user");
}

export function getToken() {
    return localStorage.getItem("token");
}

export function getFullname() {
    return localStorage.getItem("fullname");
}

export async function verifyCustomerAccountCode(accountCode) {
    const result = await axiosGet(`get/customer/name?accountCode=${accountCode}`);
    return result;
}

export const OP_BAL_Date = localStorage.getItem("op_date");

export const dbName = "receipts";
export const savingsCodesListDbName = "savingsListDb";
export const sharesCodesListDbName = "sharesListDb";
export const accountsCodesDbName = "accountCodesDb";

export const OfflineSyncStatusStore = atom({
    key: 'OfflineSyncStatusStore',
    default: false,
    dangerouslyAllowMutability: true
})
